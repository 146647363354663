import { useState, useEffect, useRef, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
// import { OrSearchCheck, SemanticSearchCheck } from '@/components'
import { useNavigate } from 'react-router-dom'
import Downshift from 'downshift'
import * as S from './SearchBar.styled'
import { db } from '@/db/RecentlySearchedKeywordDB'
// import { RootState, useSelector } from '@/store'
// import { useDispatch } from 'react-redux'
// import { searchSagaActions } from '@/store/saga/searchSaga'
import camera from '@/assets/images/camera.svg'
import file_upload from '@/assets/images/file_upload.svg'
import { useLayer } from '@/hooks'
import { useTranslation } from 'react-i18next'

interface SearchBar {
  searchText: string
  setSearchText(searchText: string): void
  popularWord?: string[]
}

export function SearchBar({ searchText, setSearchText, popularWord }: SearchBar): JSX.Element {
  const navigate = useNavigate()
  // const dispatch = useDispatch()
  // const [semanticSearch, setSemanticSearch] = useState<boolean>(
  //   useSelector((state: RootState) => state.search.semanticSearchCheckStatus),
  // )

  // const [orSearch, setOrSearch] = useState<boolean>(
  //   useSelector((state: RootState) => state.search.orSearchCheckStatus),
  // )
  const [placeHolderHidden, setPlaceHolderHidden] = useState<boolean>(false)

  useEffect(() => {
    setPlaceHolderHidden(true)
    inputRef.current?.focus()
  }, [searchText])

  // useEffect(() => {
  //   dispatch(searchSagaActions.setSemanticSearchCheckAction(semanticSearch))
  // }, [semanticSearch, dispatch])

  // useEffect(() => {
  //   dispatch(searchSagaActions.setOrSearchCheckAction(orSearch))
  // }, [orSearch, dispatch])

  const inputRef = useRef<HTMLInputElement | null>(null)

  // const items = useSelector((state) => state.search.acList)

  // 이미지 검색
  const { isShow, handleIsShow } = useLayer()

  // 사용자가 업로드한 파일 정보 확인
  const onDrop = <T extends File>(acceptedFiles: T[]) => {
    // if (acceptedFiles.length > 1) {
    //   window.alert('여러 이미지를 검색할 수 없습니다.')
    //   return
    // }

    const reader = new FileReader()

    reader.onload = function (e: ProgressEvent<FileReader>) {
      if (e.target) {
        const base64Image = e.target.result

        localStorage.setItem('imageLensFileUrl', base64Image as string)
        navigate(`/imageLens?filename=${acceptedFiles[0].name}`)
      }
    }

    reader.readAsDataURL(acceptedFiles[0])
  }

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.bmp', '.tif', '.webp'],
    },
    multiple: false,
  })

  const ref = useRef<HTMLDivElement>(null)

  const handler = useCallback(
    (e: MouseEvent) => {
      const target = e.target as HTMLElement

      // 파일 검색 레이어의 파일 업로드 버튼 클릭 시 layer 닫힘 무시
      if (target.id === 'fileUploadBtn') return

      if (ref.current && !ref.current.contains(target)) {
        handleIsShow(false)
      }
    },
    [handleIsShow],
  )

  useEffect(() => {
    if (isShow) {
      setTimeout(() => {
        window.addEventListener('click', handler)
      }, 0)
    }

    return () => window.removeEventListener('click', handler)
  }, [handler, isShow])

  const { t } = useTranslation()

  return (
    <S.SearchBarWrapper>
      <div>
        <div style={{ position: 'relative' }}>
          <form
            onSubmit={() => {
              searchText.trim() && db.add(searchText)
              if (searchText === '') sessionStorage.setItem('sortitem', 'pub_desc')
              if (
                !sessionStorage.getItem('detail') &&
                sessionStorage.getItem('sortitem') !== 'score_desc'
              )
                sessionStorage.setItem('detail', 'true')
              navigate(`/search?keyword=${searchText}`)
            }}
          >
            <Downshift
              onChange={(selection) => {
                setSearchText(selection)
                db.add(selection)
                if (searchText === '') sessionStorage.setItem('sortitem', 'pub_desc')
                if (
                  !sessionStorage.getItem('detail') &&
                  sessionStorage.getItem('sortitem') !== 'score_desc'
                )
                  sessionStorage.setItem('detail', 'true')
                navigate(`/search?keyword=${selection}`)
              }}
              inputValue={searchText}
            >
              {({
                getInputProps,
                // getItemProps,
                // getMenuProps,
                // isOpen,
                // highlightedIndex,
                getRootProps,
                inputValue,
              }) => (
                <S.SearchBar {...getRootProps()}>
                  <S.SearchBarInner>
                    <S.SearchBarInputGroup>
                      <input
                        {...getInputProps({
                          onSelect: () => {
                            setSearchText('' + inputValue)
                          },
                          onChange: (e) => {
                            setSearchText(e.target.value)
                          },
                          onBlur: (e) => {
                            if (!e.target.value.trim().length) {
                              setPlaceHolderHidden(false)
                            }
                          },
                          value: searchText,
                        })}
                        ref={inputRef}
                      />
                    </S.SearchBarInputGroup>
                    {/* {!semanticSearch && (
                      <S.SuggestedKeywords {...getMenuProps()}>
                        {isOpen &&
                          items.map((item, index) => (
                            <S.SuggestedKeyword
                              key={`${item}-${index}`}
                              isFocused={highlightedIndex === index}
                              {...getItemProps({ key: `${item}-${index}`, index, item })}
                            >
                              {item}
                            </S.SuggestedKeyword>
                          ))}
                      </S.SuggestedKeywords>
                    )} */}
                  </S.SearchBarInner>
                </S.SearchBar>
              )}
            </Downshift>
            <S.Placeholder
              isHidden={placeHolderHidden}
              onClick={() => {
                inputRef.current?.focus()
                setPlaceHolderHidden(true)
              }}
            >
              {t('components.SearchBar.Default1')}
              <strong>{t('components.SearchBar.Default2')}</strong>
              {t('components.SearchBar.Default3')}
              <strong>{t('components.SearchBar.Default4')}</strong>
              {t('components.SearchBar.Default5')}
            </S.Placeholder>
          </form>
          {/* 이미지 검색 */}
          <div ref={ref}>
            <S.ImgSearchBth onClick={() => handleIsShow(true)}>
              <img src={camera} alt='이미지 검색' />
            </S.ImgSearchBth>
            {isShow && (
              <S.ImgSearchLayer>
                <S.DropzoneWrapper {...getRootProps()} $isDragActive={isDragActive}>
                  <label htmlFor='fileInput'>{t('components.SearchBar.ImageUpload')}</label>
                  <input id='fileInput' {...getInputProps()} />
                  <img width='30' height='30' src={file_upload} alt='이미지 검색' />
                  <p>
                    {t('components.SearchBar.ImageDetail1')}
                    <br />
                    {t('components.SearchBar.ImageDetail2')}
                  </p>
                </S.DropzoneWrapper>
                <button id='fileUploadBtn' className='submit_btn' type='button' onClick={open}>
                  {t('components.SearchBar.ImageButton')}
                </button>
              </S.ImgSearchLayer>
            )}
          </div>
        </div>

        <S.SearchBarSecondChildWrapper>
          {/* <OrSearchCheck
            initialValue={orSearch}
            setChangedValue={setOrSearch}
            style={{ marginRight: 24 + 'px' }}
          />
          <SemanticSearchCheck initialValue={semanticSearch} setChangedValue={setSemanticSearch} /> */}
          {popularWord && popularWord?.length >= 1 && (
            <>
              {/* <span className='bar'>|</span> */}
              <div className='group_keywords'>
                <span className='title'>{t('components.SearchBar.Popular')}</span>
                {popularWord.map((word, index) => (
                  <span key={`${word}-${index}`} className='word'>
                    <button
                      type='button'
                      onClick={() => {
                        db.add(word)
                        navigate(`/search?keyword=${word}`)
                      }}
                      title={word}
                    >
                      {word}
                    </button>
                  </span>
                ))}
              </div>
            </>
          )}
        </S.SearchBarSecondChildWrapper>
      </div>

      {/* <img id='preview' /> */}
    </S.SearchBarWrapper>
  )
}
