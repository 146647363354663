import React, { useCallback } from 'react'

import styled from '@emotion/styled'
import PdfViewer from './PdfViewer'
import parse from 'html-react-parser'
import { checkFileExtention, keywordHighlighting } from '@/functions'

const Container = styled.div`
  font-size: 1.4rem;
  line-height: 160%;
  font-weight: 400;
  letter-spacing: -0.01em;

  pre {
    height: 100%;
    padding: 5px 12px;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    overflow: auto;
    white-space: pre-wrap;
  }
`

interface FileViewerProps {
  file: string
  contents?: string
  keyword: string
  page: number
  trans_pdf_path?: string
}

export function FileViewer({ file, contents, keyword, page, trans_pdf_path }: FileViewerProps) {
  const fileExtensionNameArr = [
    'pdf', // pdf
    'hwp',
    'hwpx',
    'doc',
    'docx',
    'csv',
    'xls',
    'xlsx',
    'ppt',
    'pptx', // doc
    'jpg',
    'png', // image
    'wav', // audio
  ]

  const exportExtenstionViewer = useCallback(
    (name: string | null) => {
      switch (name) {
        case 'pdf':
          return <PdfViewer file={file} page={page} />
        case 'jpg':
          return <img src={`${process.env.REACT_APP_BASE_URL}${file}`} alt="document's image" />
        case 'png':
          return <img src={`${process.env.REACT_APP_BASE_URL}${file}`} alt="document's image" />
        case 'wav':
          return <pre>{contents && parse(keywordHighlighting(contents, keyword))}</pre>
        default:
          /** 현재는 위의 4가지가 아닌 경우 doc */
          /** doc인 경우 file에 trans_pdf_path를 넣어 뷰잉 */
          if (trans_pdf_path) return <PdfViewer file={trans_pdf_path} page={page} />
          else return null
        // if (trans_pdf_path) console.log(trans_pdf_path)
        // return <DocViewer file={file} />
      }
    },
    [contents, file, keyword, page, trans_pdf_path],
  )

  return (
    <Container>{exportExtenstionViewer(checkFileExtention(file, fileExtensionNameArr))}</Container>
  )
}
