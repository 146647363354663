import axios from '@/services'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as LinkExternal } from '@/assets/svg/link-external.svg'

interface DocViewerProps {
  file: string
}

export function DocViewer({ file }: DocViewerProps) {
  const openPopup = async (file: string) => {
    const file_path = file.replace('/file', '')

    const response = await axios.post(
      'hancom/upload',
      {
        file_path: file_path,
      },
      {
        responseType: 'json',
        responseEncoding: 'utf-8',
      },
    )

    if (response.data.data.code === '0000') {
      const popupFeatures = `width=${window.screen.width - 300},
                             height=${window.screen.height - 100},
                             resizable=yes,
                             scrollbars=yes`

      const convert = file_path.toLowerCase().endsWith('.pdf') ? 'png' : 'html'

      window.open(
        `/hdv/view/?ext_to=${convert}&file_path=${response.data.data.upload_file_path}`,
        '_blank',
        popupFeatures,
      )
    }
  }

  const { t } = useTranslation()

  return (
    <a href='javascript:void(0)' onClick={() => openPopup(file)}>
      <LinkExternal />
      {t('components.DocViewer.PreView')}
    </a>
  )
}
